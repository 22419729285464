import "core-js/modules/es.array.push.js";
import InputField from './InputField.vue';
import Loading from './loading.vue';
import FapiaoFAQ from './FapiaoFAQ.vue';
export default {
  components: {
    InputField,
    Loading,
    FapiaoFAQ
  },
  inject: ['reload'],
  data() {
    return {
      receiveType: '',
      receive: '',
      receiveph: '',
      receivephi: '',
      errmsg: false,
      FailErrMsg: '',
      correct: '',
      showFail: false,
      DialogErrMsg: '',
      hrwidth: document.documentElement.clientWidth - 63 + 'px',
      closeyes: 'closebtny btnyes systemfont',
      closecancel: 'closebtny btncancel systemfont',
      languagecn: 'language_cn systemfont',
      languageen: 'language_en systemfont',
      showBottom: false,
      searchbtn: 'searchbtn searchbtnBgdnon systemfont',
      kptqcodestyle: 'frame van_kpborder systemfont',
      addspace: false,
      showMain: true,
      showLoading: false,
      invoiceRequest: {},
      timer: null,
      changeIndex: 0
    };
  },
  mounted() {
    this.$i18n.locale = this.$store.state.lang;
    if (this.$store.state.lang == 'en') {
      this.languagecn = 'language_cn langunchosen systemfont';
      this.languageen = 'language_en langchosen systemfont';
      this.addspace = true;
    } else {
      this.languagecn = 'language_cn langchosen systemfont';
      this.languageen = 'language_en langunchosen systemfont';
      this.addspace = false;
    }
    this.invoiceRequest = this.$store.state.invoiceRequest;
    if (this.invoiceRequest.MobilePhone || this.invoiceRequest.Emails) {
      if (this.invoiceRequest.MobilePhone.trim()) {
        this.receiveType = 'phone';
        this.receiveph = this.$t('VeriPage.inputmobile');
        this.receivephi = this.$t('VeriPage.inputmobilei');
        this.FailErrMsg = this.$t('ErrMsgVeriPage.Mobile');
        this.correct = this.invoiceRequest.MobilePhone.substring(7, 11);
      } else {
        this.receiveType = 'emile';
        this.receiveph = this.$t('VeriPage.inputemail');
        this.receivephi = this.$t('VeriPage.inputemaili');
        this.FailErrMsg = this.$t('ErrMsgVeriPage.Email');
        this.correct = this.invoiceRequest.Emails;
      }
    } else {
      this.receiveType = 'phone';
      this.receiveph = this.$t('VeriPage.inputmobile');
      this.receivephi = this.$t('VeriPage.inputmobilei');
      this.FailErrMsg = this.$t('ErrMsgVeriPage.Mobile');
      this.correct = '';
    }
  },
  watch: {
    receive: {
      // immediate: true,
      handler: function (newValue) {
        this.onWatchReceive(newValue);
      }
    }
  },
  methods: {
    clearBlank() {
      this.receive = this.receive.replace(/\s+/g, '');
    },
    focused() {
      this.changeIndex = 1;
      document.getElementById("receiveid").setAttribute("placeholder", "");
      if (!this.receive) {
        document.getElementById("receiveid").setAttribute("placeholder", this.receiveph);
      }
      this.kptqcodestyle = 'frame van_kpbordering systemfont';
      this.errmsg = false;
    },
    unfocused() {
      if (this.receiveType == 'phone') {
        var numReg = /^\d{4}$/;
      } else {
        var numReg = "(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])";
      }
      document.getElementById("receiveid").setAttribute("placeholder", "");
      if (this.receive) {
        this.changeIndex = 1;
      } else {
        this.changeIndex = 0;
      }
      var numRe = new RegExp(numReg);
      if (numRe.test(this.receive)) {
        this.kptqcodestyle = 'frame van_kpborder systemfont';
        this.errmsg = false;
      } else {
        if (this.receive) {
          this.kptqcodestyle = 'frame van_kpbordererr systemfont';
          this.errmsg = true;
        } else {
          this.kptqcodestyle = 'frame van_kpborder systemfont';
          this.errmsg = false;
        }
      }
    },
    onWatchReceive(newValue) {
      if (this.receiveType == 'phone') {
        var numReg = /^\d{4}$/;
      } else {
        var numReg = "(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])";
      }
      if (newValue) {
        this.changeIndex = 1;
        document.getElementById("receiveid").setAttribute("placeholder", "");
      } else {
        document.getElementById("receiveid").setAttribute("placeholder", this.receiveph);
      }
      this.errmsg = false;
      var numRe = new RegExp(numReg);
      if (numRe.test(newValue)) {
        this.searchbtn = 'searchbtn searchbtnBgd systemfont';
      } else {
        this.searchbtn = 'searchbtn searchbtnBgdnon systemfont';
      }
    },
    onClickSubmit() {
      if (this.receiveType == 'phone') {
        var numReg = /^\d{4}$/;
      } else {
        var numReg = "(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])";
      }
      var numRe = new RegExp(numReg);
      if (this.receive) {
        if (!numRe.test(this.receive)) {
          this.kptqcodestyle = 'frame van_kpbordererr systemfont';
          this.searchbtn = 'searchbtn searchbtnBgdnon systemfont';
          this.errmsg = true;
          this.$store.commit('setReceiveStatus', false);
          return false;
        }
      } else {
        this.kptqcodestyle = 'frame van_kpbordererr systemfont';
        this.searchbtn = 'searchbtn searchbtnBgdnon systemfont';
        this.errmsg = true;
        this.$store.commit('setReceiveStatus', false);
        return false;
      }
      if (this.receive.toLowerCase() == this.correct.toLowerCase()) {
        this.showMain = false;
        this.showLoading = true;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$router.push('kpsuccess');
        }, 500);
      } else {
        this.showFail = true;
      }
    },
    onChangeLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('setLang', lang);
      localStorage.setItem('lang', lang);
      // this.reload();
      if (this.receiveType == 'phone') {
        this.FailErrMsg = this.$t('ErrMsgVeriPage.Mobile');
        this.receiveph = this.$t('VeriPage.inputmobile');
        this.receivephi = this.$t('VeriPage.inputmobilei');
      } else {
        this.receiveph = this.$t('VeriPage.inputemail');
        this.receivephi = this.$t('VeriPage.inputemaili');
        this.FailErrMsg = this.$t('ErrMsgVeriPage.Email');
      }
      if (lang == 'en') {
        this.languagecn = 'language_cn langunchosen systemfont';
        this.languageen = 'language_en langchosen systemfont';
        this.bannertitle = 'bannertitlex systemfont';
        this.bannercontent = 'bannertitlez systemfont';
        this.addspace = true;
        document.title = 'Shanghai Disney Resort';
      } else if (lang == 'zh') {
        this.languagecn = 'language_cn langchosen systemfont';
        this.languageen = 'language_en langunchosen systemfont';
        this.bannertitle = 'bannertitlet systemfont';
        this.bannercontent = 'bannertitlef systemfont';
        this.addspace = false;
        document.title = '上海迪士尼度假区';
      }
    }
  }
};