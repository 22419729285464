import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue";
import _imports_0 from '../../../public/castle.png';
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  class: "backgroundcss"
};
const _hoisted_4 = {
  style: {
    "float": "right",
    "padding": "0px 14px",
    "height": "44px",
    "display": "flex",
    "flex-direction": "row",
    "align-items": "center"
  }
};
const _hoisted_5 = {
  style: {
    "padding-top": "62px"
  }
};
const _hoisted_6 = {
  key: 0,
  style: {
    "float": "left",
    "z-index": "2"
  }
};
const _hoisted_7 = {
  class: "bannertitlex systemfont"
};
const _hoisted_8 = {
  class: "bannertitlef systemfont"
};
const _hoisted_9 = {
  key: 1,
  style: {
    "float": "left",
    "z-index": "2"
  }
};
const _hoisted_10 = {
  class: "bannertitlet systemfont"
};
const _hoisted_11 = {
  class: "bannertitlef systemfont"
};
const _hoisted_12 = {
  style: {
    "padding": "8px 12px 0 26px",
    "word-break": "break-all",
    "word-wrap": "break-word",
    "height": "18px"
  }
};
const _hoisted_13 = {
  class: "errmsg systemfont"
};
const _hoisted_14 = {
  style: {
    "padding": "12px 0 0 0",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_15 = {
  class: "poptitleab systemfont"
};
const _hoisted_16 = {
  style: {
    "position": "absolute",
    "right": "0",
    "bottom": "0",
    "left": "0",
    "top": "54px",
    "overflow": "auto"
  }
};
const _hoisted_17 = {
  class: "errtitle systemfont"
};
const _hoisted_18 = {
  class: "errcont systemfont"
};
const _hoisted_19 = {
  style: {
    "margin": "30px 14px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading");
  const _component_van_field = _resolveComponent("van-field");
  const _component_FapiaoFAQ = _resolveComponent("FapiaoFAQ");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", null, [$data.showLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Loading)])) : _createCommentVNode("", true), $data.showMain ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
    class: _normalizeClass($data.languagecn),
    onClick: _cache[0] || (_cache[0] = $event => $options.onChangeLanguage('zh'))
  }, "中", 2), _cache[9] || (_cache[9] = _createElementVNode("div", {
    class: "language_middle"
  }, null, -1)), _createElementVNode("div", {
    class: _normalizeClass($data.languageen),
    onClick: _cache[1] || (_cache[1] = $event => $options.onChangeLanguage('en'))
  }, "EN", 2)]), _createElementVNode("div", _hoisted_5, [$data.addspace ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('VeriPage.ViewFapiao')), 1), _createElementVNode("div", _hoisted_8, [_createTextVNode(_toDisplayString(_ctx.$t('VeriPage.inputfirstf')), 1), _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)), _createTextVNode(_toDisplayString(_ctx.$t('VeriPage.inputfirsts')), 1)])])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('VeriPage.ViewFapiao')), 1), _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('VeriPage.inputfirst')), 1)])), _cache[11] || (_cache[11] = _createElementVNode("div", {
    style: {
      "float": "right",
      "position": "absolute",
      "top": "62px",
      "right": "14px"
    }
  }, [_createElementVNode("img", {
    src: _imports_0,
    style: {
      "max-width": "90px",
      "max-height": "60px"
    }
  })], -1))])]), _createElementVNode("div", null, [_createElementVNode("div", {
    class: _normalizeClass($data.kptqcodestyle)
  }, [_createVNode(_component_van_field, {
    id: "receiveid",
    onFocus: $options.focused,
    onBlur: $options.unfocused,
    onKeyup: $options.clearBlank,
    autocomplete: "off",
    class: "uptextfont",
    modelValue: $data.receive,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.receive = $event),
    clearable: ""
  }, null, 8, ["onFocus", "onBlur", "onKeyup", "modelValue"]), _createElementVNode("span", {
    class: _normalizeClass([{
      'focusBlur': $data.changeIndex == 1
    }, {
      'focusBlurTwo': $data.changeIndex == 0
    }])
  }, _toDisplayString($data.receivephi), 3)], 2), _withDirectives(_createElementVNode("div", _hoisted_12, [_createElementVNode("span", _hoisted_13, _toDisplayString($data.FailErrMsg), 1)], 512), [[_vShow, $data.errmsg]])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("span", {
    class: "cliclhere systemfont",
    onClick: _cache[3] || (_cache[3] = $event => this.showBottom = true)
  }, _toDisplayString(_ctx.$t('AppPage.FapiaoFAQ')), 1)]), _createVNode(_component_van_popup, {
    show: $data.showBottom,
    "onUpdate:show": _cache[4] || (_cache[4] = $event => $data.showBottom = $event),
    round: "",
    closeable: "",
    position: "bottom",
    style: {
      height: '90%'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('ManPage.poptitle')), 1), _createElementVNode("div", _hoisted_16, [_createVNode(_component_FapiaoFAQ)])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showFail,
    "onUpdate:show": _cache[7] || (_cache[7] = $event => $data.showFail = $event),
    round: "",
    style: {
      margin: '28px',
      padding: '20px',
      width: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('ManPage.errtitle')), 1), _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('ErrMsgVeriPage.Check')), 1), _createElementVNode("button", {
      class: _normalizeClass($data.closeyes),
      onClick: _cache[5] || (_cache[5] = $event => this.showFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closeyes')), 3), _createElementVNode("button", {
      class: _normalizeClass($data.closecancel),
      onClick: _cache[6] || (_cache[6] = $event => this.showFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closecancel')), 3)]),
    _: 1
  }, 8, ["show"]), _createElementVNode("div", _hoisted_19, [_createElementVNode("button", {
    class: _normalizeClass($data.searchbtn),
    onClick: _cache[8] || (_cache[8] = (...args) => $options.onClickSubmit && $options.onClickSubmit(...args))
  }, _toDisplayString(_ctx.$t('VeriPage.Check')), 3)])])) : _createCommentVNode("", true)]);
}